import React from 'react'
import './style.less';
import { Row, Col } from 'antd';

class VideoIntro extends React.Component {

    render(){

        return (
            <Row className="video-intro">
                <Col xs={{span: 24}}>
                    <h2 className="big">{this.props.title}</h2>
                </Col>
                <Col xs={{span: 20, offset: 2}} md={{span:20, offset: 4}}>
                    <div className="responsive-video">
                        <iframe title="video-intro-video" src={"https://player.vimeo.com/video/" + this.props.videoID + "?title=0&byline=0&portrait=0"} frameBorder="0"></iframe>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default VideoIntro